import React, {Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {useStyles} from './style';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Grid } from '@material-ui/core';

export default function ComboBoxAutoComplete(props) {
	const classes = useStyles();
	return (
		<Fragment  key={props.name}>
			{props?.showSelectAutoComplete && (
            <Grid className={classes.grid}  key={props.name}>
                <Autocomplete
                    key={props.name}
                    value={props.value}
                    id={props.name}
                    name={props.name}
                    onChange={(event, newValue) => {
                        if(newValue) {
                            props.handleChange({ target: {value: newValue?.id, name: props.name, text: newValue?.name} });
                        } else {
                            props.handleChange({target: {value:'', name: props.name, text:''}});
                        }
                    }}
                    disabled={props.disabled}
                    options={props.list || []}
                    noOptionsText="No encontrado"
                    getOptionLabel={(option) => {
                        return option ? option.name : option
                    }}
                    defaultValue={ (option) => {
                        return option ? option.name || option : ''
                    }}
                    getOptionSelected={ () => true}
                    // onInputChange={(event, newInputValue) => {
                    // 	console.log("onInputChange2", `**${newInputValue}**`);
                    // 	return newInputValue ? newInputValue : ''
                    // }}
                    renderInput={(params) =>
                        <TextField
                            autoFocus={props.autoFocus}
                            id={props.name}
                            name={props.name}
                            {...params} label={`${props.label}`}  className={classes.textField}
                        />
                    }
                />
                {(props.isError) ? <FormHelperText className="Mui-error" id="component-error-text">{props.errorMessages}</FormHelperText> : null }
			</Grid>
		)}
		</Fragment>
	);
}

ComboBoxAutoComplete.defaultProps = {
    showSelectAutoComplete: true,
    autoFocus: false,
}