import {actionNames} from "constants/actionNames";

const initialState = {
    departments: [],
    catalogos:{
        municipalities:[], orientacionSexual: [], etnia: [], identidadGenero: [],
        discapacidades: [], comunidadLinguistica: [], cargoElectoral: [],
        nivelEducativo: [], profesionUOficio: [], institucionesEducativas: [], nivelEducativoActual: [],
        rangoExperiencia: [], sectorLaboral: [], listCatalogosPorFormulario: []
    },
};

export default (state = initialState, action ) => {
    switch (action.type) {
        // general data
        case actionNames.SET_SEXUAL_ORIENTATION:
            return {
                ...state,
                catalogos: {...state.catalogos, orientacionSexual: action.payload},
            }
        case actionNames.SET_ETHNICITY:
            return {
                ...state,
                catalogos: {...state.catalogos, etnia: action.payload},
            }
        case actionNames.SET_LINGUISTIC_COMMUNITY:
            return {
                ...state,
                catalogos: {...state.catalogos, comunidadLinguistica: action.payload},
            }
        case actionNames.SET_GENDER_IDENTITY:
            return {
                ...state,
                catalogos: {...state.catalogos, identidadGenero: action.payload},
            }
        case actionNames.SET_DISABILITIES:
            return {
                ...state,
                catalogos: {...state.catalogos, discapacidades: action.payload},
            }
            // VOTER TURNOUT
        case actionNames.SET_ELECTORAL_CHARGE:
            return {
                ...state,
                catalogos: {...state.catalogos, cargoElectoral: action.payload},
            }
            // EDUCATION LEVEL
        case actionNames.SET_EDUCATION_LEVEL_LIST:
            return {
                ...state,
                catalogos: {...state.catalogos, nivelEducativo: action.payload},
            }
        case actionNames.SET_PROFESSION_OR_OCCUPATION:
            return {
                ...state,
                catalogos: {...state.catalogos, profesionUOficio: action.payload},
            }
        case actionNames.SET_EDUCATIONAL_INSTITUTIONS:
            return {
                ...state,
                catalogos: {...state.catalogos, institucionesEducativas: action.payload},
            }
            // WORK EXPERIENCE
        case actionNames.SET_RANGE_EXPERIENCE:
            return {
                ...state,
                catalogos: {...state.catalogos, rangoExperiencia: action.payload},
            }
        case actionNames.SET_LABORAL_SECTOR:
            return {
                ...state,
                catalogos: {...state.catalogos, sectorLaboral: action.payload},
            }
        case actionNames.SET_CURRENT_EDUCATION_LEVEL_LIST:
            return {
                ...state,
                catalogos: {...state.catalogos, nivelEducativoActual: action.payload},
            }
        case actionNames.GET_DEPARTMENTS:
            return {
                ...state,
                departments: action.payload,
            }
        case actionNames.LIST_CATALOGOS_POR_FORMULARIO:
            return {
                ...state,
                catalogos: {...state.catalogos, listCatalogosPorFormulario: action.payload},
            }
        case actionNames.GET_MUNICIPALITIES:
            let newState = Object.assign({}, state);
            newState.catalogos.municipalities= state.catalogos.municipalities.length > 0 ? state.catalogos.municipalities.concat(action.payload) : action.payload;
            return newState;
        default:
            return state;
    }
}