import React from 'react';
import {IconButton, Tooltip} from '@material-ui/core';
import { createTheme, MuiThemeProvider, makeStyles } from "@material-ui/core/styles";

function CustomToolTip({title, size, color, handleFunction, children}) {
  const useStyles = makeStyles(() => ({
    iconButton: {
      color: '#205690',
    }
  }));

  const theme = createTheme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: "1rem",
          color: "white",
          backgroundColor: "#205690",
          borderRadius: 10,
          padding: '1rem',
          fontWeight: 'bold',
          textTransform: 'uppercase',
          textAlign: 'center'
        }
      }
    }
  });
  const classes = useStyles();

  return (
    <MuiThemeProvider theme={theme}>
      <Tooltip title={title}>
          <IconButton size={size} className={classes.iconButton} onClick={handleFunction}>{children}</IconButton>
      </Tooltip>
    </MuiThemeProvider>

  );
}

CustomToolTip.defaultProps = {
  size: "medium",
  handleFunction: () => {}
}

export default CustomToolTip;