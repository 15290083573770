import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';
import {Icon, Button, Slide } from '@material-ui/core/';
import {useStyles} from './style';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ImagePrev(props) {
    const classes = useStyles();

    const [scroll] = React.useState('paper');

    return (
        <div>
            <Dialog
                maxWidth={"xl"}
                className={classes.rootSize}
                open={props.open}
                fullWidth={true}
                scroll={scroll}
                TransitionComponent={Transition}
                keepMounted
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle className={classes.title} id="alert-dialog-slide-title">{props.title}</DialogTitle>

                <DialogContent className={classes.dialogContent} dividers={scroll === 'paper'}>
                    <img  className={classes.img} src={ props.base64  ? props.base64.includes('base64,') ? props.base64 : `data:image/jpeg;base64,${props.base64}` : props.src } alt="Previsualización Imagen" />
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.handleClose} color="secondary" variant="contained">
                        <Icon className={classes.icon}> cancel </Icon>
                        Cerrar
                    </Button>
               </DialogActions>
            </Dialog>
        </div>
    );
}