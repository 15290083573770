import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( () => ({
    description:{
        fontSize: "20px",
        fontWeight: 400,
        letterSpacing: ".3px",
        lineHeight: "1.5rem",
        color: "#000000",
        whiteSpace: "pre-wrap",
        textAlign: 'center',
        marginBottom: '1.5rem',
        '@media (max-width:992px)':{
            fontSize: "18px",
        },
        '@media (max-width:768px)':{
            fontSize: "16px",
        },
    },
    image: {
        display: "block",
        // width: "60%",
        height: "30%",
        maxHeight: '30%',
        margin: 'auto',
        height: "auto",
    }
}));

