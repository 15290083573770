import React from 'react';
import {FormControlLabel, FormHelperText, FormControl, FormLabel} from '@material-ui/core/';
import {stylesCheckbox} from './stylesCheckbox'
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';

export default function FormControlInputCheckbox(props) {
    const classes = stylesCheckbox();
    return (
      <>
        {props.showInputCheckox && (
          <FormControl className={classes.formControl} component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
                <FormGroup
                    name={props.idelement}
                    value={props.value}
                    className={classes.radioGroup}
                    onChange={(e) => { props.handleChangeCheckbox(e, props.value) }} >
                    {props.options?.map( ({value, label}) => (
                        <FormControlLabel
                            key={value}
                            value={value}
                            control={<Checkbox />}
                            label={label}
                            className={classes.elementGroupClasses}
                            name={props.idelement}
                        />
                    ))
                    }
                </FormGroup>
                {(props.isError) ? <FormHelperText className="Mui-error" id="component-error-text">{props.errorMessages}</FormHelperText> : null }
          </FormControl>
        )}
      </>
    )
}

FormControlInputCheckbox.defaultProps = {
  showInputCheckox: true,
  label: ""
}