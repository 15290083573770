import React, { useEffect } from 'react';
import { useStyles } from './styles.js';
import {Stepper, Step, StepLabel, Button, Typography, Grid} from '@material-ui/core';
export default function CustomHorizontalLinearStepper({getStepContent, optional, closeFullScreenForm, steps, showButtonSaveData, activeStepProp, numberStepProp, showButtonStepper}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(activeStepProp);
  const [numberStep, setNumberStep] = React.useState(numberStepProp);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === optional;
  };

  useEffect(() => {
    setActiveStep(activeStepProp)
    setNumberStep(numberStepProp)
  }, [activeStepProp, numberStepProp])

  // VALIDATE IF THE SKIPPED  CONTAIN THE VALUE
  // HAS IS USED TO VALIDATE THE VALUE EXIST IN THE new Set()
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  // FOLLOWING STEP
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    if(numberStep === steps.length) {
      return;
    }

    // CHANGE DE CURRENT STEP
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setNumberStep((prevNumberStep) => prevNumberStep + 1);
    setSkipped(newSkipped);
  };

  // RETURN THE LAST STEP
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setNumberStep((prevNumberStep) => prevNumberStep - 1);
  };

  // RESET THE STEP IN CLOSE DE DIALOG
  const handleClosed = () => {
    setActiveStep(0);
    closeFullScreenForm()
  };

  return (
    <div className={classes.root}>
        {/* THIS IS THE HEADER OF THE STEPPER */}
      <Stepper activeStep={activeStep}>
        {steps?.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <Typography variant="caption">No existe un componente para renderizar</Typography>;
          }
          return (
            <Step key={label?.id || label} {...stepProps}>
              <StepLabel {...labelProps}>{label?.label || label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
        <Grid container spacing={2}>
            {/* THIS IS THE CONTENT TO RENDER */}
            <Grid item xs={12} className={classes.content}>{getStepContent(activeStep)}</Grid>
            {(showButtonStepper && steps?.length > 0) && (
              <Grid item xs={12} className={classes.containerButton}>

                <Button disabled={activeStep === 0} onClick={handleBack} className={classes.buttonBack}>  Regresar </Button>

                <Button
                  variant="contained"
                  onClick={handleNext}
                  className={classes.buttonNext}
                  disabled={numberStep === steps.length}
                >  Siguiente</Button>

                {showButtonSaveData && numberStep === steps.length && (
                  <Button
                    variant="contained"
                    onClick={handleNext}
                    className={classes.buttonNext}
                  >  Guardar Datos</Button>
                )}

                <Button onClick={handleClosed} className={classes.buttonClosed}> CERRAR </Button>

              </Grid>
            )}
        </Grid >
    </div>
  );
}

CustomHorizontalLinearStepper.defaultProps = {
  optional: "",
  showButtonSaveData: false,
  showButtonStepper: true
}
