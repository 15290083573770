import React, {useState, Fragment, useEffect} from 'react';
import { Card, CardContent } from '@material-ui/core';
import Form from 'components/Form/FormTwoColumns';
import {Close, DeleteOutlined, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { customFetchListPublic, customSetDataStore } from 'store/actions/customAction';
import { useDispatch, useSelector } from 'react-redux';
import { actionNames } from 'constants/actionNames';
import { useHistory } from 'react-router-dom';
import ApiServices from 'services/ApiServices';
import { showMessageError } from 'services/SweetAlert';
import LoadingSpinner from 'components/LoadingSpinner';
import ResponseElement from 'components/ResponseElement';
import Table from 'components/Table';
import Modal from 'components/ModalConfirmation';
import CustomToolTip from 'components/tooltip/CustomToolTip';

let dataToValidate = [];

const WorkExperience = ({activeStep, numberStep, workExperience, typeForm={typeForm}}) => {

    let history = useHistory();
    const dispatch = useDispatch();
    const [responseData, setResponseData] = useState({ errorType: 'error', messageType: '', openResponse: false, });
    const {trabajaActual, sectorLabora, nombreInstitucionLabora, otraExperienciaLaboral, rangoExperienciaLaboral, nombresInstitucionesLaborado} = workExperience;
    const[loadingRequest, setLoadingRequest] = useState(false);
    const [controllers] = useState({rangoExperiencia: "listRangoExperiencia", sectorLaboral: "listSectorLaboral", listCatalogosPorFormulario: "listCatalogosPorFormulario" })
    const[catalogoController] = useState("catalogos");
    const {general} = useSelector((state) => state),
    [dataItem, setDataItem] = useState({}),
    [showDeleteInstitucion, setShowDeleteInstitucion] = useState(false),
    [institucionsList, setInstitucionsList] = useState(nombresInstitucionesLaborado);
    dataToValidate = institucionsList;

    const [header] = useState([
        { title: 'Nombre de Institución', field: 'institucion', cellStyle:{ maxWidth: '80px'}},
        { title: 'Acción', field: 'accion',
        render: rowData=><div style={{minWidth: '100px'}}>
            {
                (<div>
                    <CustomToolTip title="Eliminar institución del listado" handleFunction={()=> removeItem(rowData)}><DeleteOutlined/></CustomToolTip>
                </div>)
            }
            </div>
        },
    ]);

    useEffect(() => {
        getData(general?.catalogos.listCatalogosPorFormulario, actionNames.LIST_CATALOGOS_POR_FORMULARIO, controllers.listCatalogosPorFormulario, "")
        validateAnswerOfCurrentJob(trabajaActual)
    }, [])

    const getData = async (stateInStore, dispatchAction, methodName, nameElement) => {
        const newState = Object.assign({}, elements);
        setLoadingRequest(true)
        const dataCustomFetch = {
            controller: catalogoController,
            validateIfDataExists: true,
            stateInStore,
            dispatchAction,
            methodName
        }

        ApiServices[catalogoController].searchCriteria.clear();
        ApiServices[catalogoController].searchCriteria.addEquals('idFormulario', typeForm.id);
        ApiServices[catalogoController].orderCriteria.clear();
        ApiServices[catalogoController].orderCriteria.addAsc('id');
        let response = await dispatch(customFetchListPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error) {
            const message = response?.error?.message || 'No se encontraron datos';
            if (response?.type === 1)  return showMessageError(message,'')
            if (response?.error?.status === 404)  return showMessageError(message, '')
            return showMessageError(message, '')
        }

        let experienceRangList = [];
        let workExperienceList = [];
        let laboralSectorList = []
        if(response?.data.length > 0) {
            const {experienciaLaboral, rangoExperiencia, sectorLaboral} = response.data[0];
            experienceRangList = experienciaLaboral.map( ({idExperienciaLaboral, name } ) => ({value: idExperienciaLaboral, label: name}))
            workExperienceList = rangoExperiencia.map( ({rangoExperiencia } ) => ({id: rangoExperiencia.id, name: rangoExperiencia.name}))
            laboralSectorList = sectorLaboral.map( ({sectorLaboral } ) => ({id: sectorLaboral.id, name: sectorLaboral.name}))
        }

        newState.ambitoExperienciaLaboral.options = experienceRangList;
        newState.rangoExperienciaLaboral.list = workExperienceList;
        newState.sectorLabora.list = laboralSectorList;
        setElements(newState);
    }

    const changeValueToElement = (element, nameShowELement, valueShowElement, pattern, value = null) => {
        elements[element][nameShowELement] = valueShowElement;
        elements[element].pattern = pattern;
        elements[element].value = value;
    }

    const validateAnswerOfCurrentJob = (value) => {
        const newElements = Object.assign({}, elements);
        if(value === '1') {
            changeValueToElement('sectorLabora', 'showSelectAutoComplete', true, "^[1-9][0-9]*$", sectorLabora)
            changeValueToElement('nombreInstitucionLabora', 'showInputFormControl', true, "^[a-zA-Z-á-úÁ-Ú\\s]{1,300}$", nombreInstitucionLabora)
        }
        else {
            changeValueToElement('sectorLabora', 'showSelectAutoComplete', false, "", )
            changeValueToElement('nombreInstitucionLabora', 'showInputFormControl', false, "", "")
        }
        setElements(newElements)
    }

    const validateSectorLabora = (value) => {
        const newElements = Object.assign({}, elements);
        if(value === '5') changeValueToElement('otraExperienciaLaboral', 'showInputFormControl', true, "^[a-zA-Z-á-úÁ-Ú\\s]{1,300}$", "")
        else changeValueToElement('otraExperienciaLaboral', 'showInputFormControl', false, "", "")
        setElements(newElements)
    }

    // cancel action of the form
    const addToTheList = (event) => {
        if (event.key === "Enter") {
            const newElements = Object.assign({}, elements)
            const {value} = event?.target;

            if(!value) {
                newElements.nombresInstitucionesLaborado.isError = true;
                return setElements(newElements)
            }

            const isError =  value.toString().match(elements.nombresInstitucionesLaborado.pattern) === null ? true : false

            if(isError) {
                newElements.nombresInstitucionesLaborado.isError = true;
                return setElements(newElements)
            }

            if(dataToValidate.length > 0 ) {
                const existElement = dataToValidate.some((item) => item.institucion === value?.toUpperCase() )
                if(existElement) return setResponseData({ errorType: 'warning', messageType: `LA INSTITUCIÓN YA FUE INGRESADA!.`, openResponse: true, });
            }

            setInstitucionsList( prevState => [{ id: new Date().getTime(), institucion: value?.toUpperCase()}, ...prevState])
            newElements.nombresInstitucionesLaborado.value = "";
            setElements(newElements)
        }
    };

    const [elements, setElements] = useState({
        trabajaActual:{
            idelement: "trabajaActual", value: trabajaActual, label: "Trabaja actualmente?", isError:false, elementType:'radio', errorMessages:['Debe marcar una opción'],
            options : [{ value: "0", label: 'No' }, { value: "1", label: 'Si' }], 'disabled':false, isRequired: true, handler: validateAnswerOfCurrentJob
        },
        sectorLabora: {
            idelement: "sectorLabora", value: sectorLabora, label: "Seleccione el sector donde labora *", pattern:"", validators: ['required'],
            errorMessages:['Seleccione el sector donde labora '], isError:false, showSelectAutoComplete: false, elementType:'autocompleteV2', list: [], handler: undefined,
        },
        nombreInstitucionLabora: {
            idelement: "nombreInstitucionLabora",  value: nombreInstitucionLabora, label: "Nombre de la institución donde labora *", pattern:"", validators: ['required'], errorMessages:['El campo es requerido con un máximo de 300 caracteres y sin espacios al inicio y final'], isError:false, elementType:'input',  autofocus: false, showInputFormControl: false
        },
        ambitoExperienciaLaboral: {
            idelement: "ambitoExperienciaLaboral", value: "", label: "Marque el ámbito de experiencia laboral : *", isError: false, elementType: 'checkbox', errorMessages: ['Debe marcar al menos una opción'], options: [], 'disabled': false, isRequired: true, showInputCheckox: true, listValues: [], handler: validateSectorLabora
        },
        otraExperienciaLaboral: {
            idelement: "otraExperienciaLaboral",  value: otraExperienciaLaboral, label: "Indique el ámbito de experiencia laboral *", pattern:"", validators: ['required'], errorMessages:['El campo es requerido con un máximo de 300 caracteres y sin espacios al inicio y final'], isError:false, elementType:'input',  autofocus: false, showInputFormControl: false
        },
        rangoExperienciaLaboral: {
            idelement: "rangoExperienciaLaboral", value: rangoExperienciaLaboral, label: "Seleccione los años de experiencia laboral *", pattern:"^[1-9][0-9]*$", validators: ['required'],
            errorMessages:['Seleccione el ámbito de experiencia laboral '], isError:false, showSelectAutoComplete: true, elementType:'autocompleteV2', list: [], handler: undefined,
        },
        formSearch:{
            idelement: "formSearch",title:'Ingrese nombre de la(s) instituciones donde ha laborado', position:"center", value: "", isError:false, elementType:'title'
        },
        nombresInstitucionesLaborado: {
            idelement: "nombresInstitucionesLaborado",  value: "", label: "Ingrese nombre y de enter", pattern:"^[\\w\\á-úÁ-Ú\\s]{0,300}$", validators: ['required'], errorMessages:['El campo es requerido con un máximo de 300 caracteres y sin espacios al inicio y final'], isError:false, elementType:'input',  autofocus: false, showInputFormControl: true, keyPress:addToTheList
        },
    });

    const saveData = async (dataForm) => {
        const newElements = Object.assign({}, elements);
        newElements.nombresInstitucionesLaborado.value  ='';
        setElements(newElements)

        dataForm.nombresInstitucionesLaborado = dataToValidate
        dataForm.ambitoExperienciaLaboral = elements.ambitoExperienciaLaboral.listValues;

        await dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_WORK_EXPERIENCE,
            data: {
                workExperience: dataForm,
                activeStep : activeStep + 1,
                numberStep : numberStep + 1
            }
        }));
    }

    // cancel action of the form
    const cancelAction = () => {
        dispatch(customSetDataStore({ dispatchAction : actionNames.CLEAN_ALL_REDUCER}));
        history.replace("/");
    };

    const backAction = () => {
        workExperience.nombresInstitucionesLaborado = dataToValidate
        dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_WORK_EXPERIENCE,
            data: {
                workExperience,
                activeStep : activeStep - 1,
                numberStep : numberStep - 1
            }
        }));
    };

    // elements button action to show of the form
    const [buttonList]= React.useState({
        "backAction":{"label":"Regresar", "icon": <NavigateBefore />,"callback":backAction,"loading":false,"size":"medium",
            "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)", "border":"0px", "borderRadius":"3",
            "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px",  disabled: false, isCancel: true,
        },
        "saveData":{"label":"Siguiente", "icon": <NavigateNext />, "callback":saveData,"loading":false,"size":"medium",
                "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)", "border":"0px", "borderRadius":"3",
                "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px", disabled: false,
                isCancel: false,
        },
        "cancelAction":{"label":"Cancelar", "icon": <Close />,"callback":cancelAction,"loading":false,"size":"medium",
                "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)", "border":"0px", "borderRadius":"3",
                "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px",  disabled: false,
                isCancel: true,
        },
    });

        // cancel action of the form
    const removeItem = (rowItem) => {
        setDataItem(rowItem)
        setShowDeleteInstitucion(true);
    }

    const handleActionModal = async () => {
        const {id} = dataItem;
        const newData = institucionsList.filter((item) => item.id !== id)

        setInstitucionsList(newData);
        setShowDeleteInstitucion(false);
    }

    const closeModalDeleteInstitucion = () => {
        setShowDeleteInstitucion(false);
    }

    // to close dialog with message Response Element
    const handClose = (_, reason) => {
        if (reason === 'clickaway') {return}
        changeDataResponse('warning', '', false)
    };

    // to change data response
const changeDataResponse = (errorTypeLocal, messageTypeLocal, openResponseLocal = false) => setResponseData({errorType: errorTypeLocal, messageType: messageTypeLocal, openResponse: openResponseLocal});


    const { errorType, messageType, openResponse} = responseData;
    return (
        <Fragment>
            <Card>
                {loadingRequest && <LoadingSpinner open={loadingRequest} /> }
                <ResponseElement type={errorType} content={messageType} open={openResponse} handClose={handClose}/>
                <CardContent>
                    <Form
                        elements={elements}
                        buttonList={buttonList}
                        />
                    <Table
                        pageSize={5}
                        showPagination={true}
                        data={institucionsList}
                        title={"Listado de instituciones donde ha laborado"}
                        header={header}
                        detailPanel={[]}
                    />
                {showDeleteInstitucion && (
                    <Modal
                        title={`¿Está seguro de eliminar la institución?`} message={"No podrá revertir esta acción"}
                        open={showDeleteInstitucion} closeModal={closeModalDeleteInstitucion} titleButton={"Sí, estoy seguro"}
                        actionAdd={handleActionModal}
                    />
                )}
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default WorkExperience;