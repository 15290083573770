import {combineReducers}  from 'redux';
import menu from './menu';
import signUp from './signUp';
import security from './security';
import general from './generalReducer';
import formRequest from './formRequestReducer';

const allReducers = combineReducers({
    menu,
    signUp,
    security,
    general,
    formRequest,
});

export default allReducers;