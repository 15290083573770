import React, { useState } from 'react';
import {Input, InputLabel} from '@material-ui/core/';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useStyles} from './style';

export default function FormControlInput(props) {
    const classes = useStyles();
    const [self] = useState(props.this);

    return (
        <>
            {props.showInputFormControl && (
                <FormControl className={classes.formControl} >
                    <InputLabel htmlFor="component-error" className={classes.InputLabel}>{props.label}</InputLabel>
                    <Input error={props.isError}
                        key={props.name}
                        id={props.name}
                        name={props.name}
                        value={props.value}
                        autoFocus={props.autofocus}
                        multiline={props.multiline}
                        onChange={(event)=>{
                            if(props.maxLength && event?.target?.value?.length > props.maxLength ) return;
                            props.handleChange(event,self)}
                        }
                        onKeyPress={props.keyPress}
                        disabled={props.disabled}
                        aria-describedby="component-error-text"
                    />
                    {(props.isError) ? <FormHelperText className="Mui-error" id="component-error-text">{props.errorMessages}</FormHelperText> : null }
                </FormControl>
            )}
        </>
    )
}

FormControlInput.defaultProps = {
    multiline : false,
    maxLength: null,
    showInputFormControl: true
}