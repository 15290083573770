import ApiServices from "services/ApiServices";

export const customListRegister = () => {}

export const customFetchListRegister = ({controller, validateIfDataExists = false, stateInStore, saveInStore = true, dispatchAction, action = 'list'}) =>  async (dispatch) => {

    // console.log({controller, validateIfDataExists, stateInStore, saveInStore, dispatchAction, action})
    try {
        // validating if you want to validate if the data exists in store then validate that the data is greater than 0
        if(validateIfDataExists && stateInStore.length > 0) return {data: stateInStore, error: null};

        const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);

        if(hasPermission.error) return {error: hasPermission.error, type: 1, };

        const response = await ApiServices[controller].listRegister();

        if(response.error) return {error: response.error, type: 2 }

        // Valitatin if you want to save in store the response
        if(saveInStore) dispatch({ type: dispatchAction, payload: response?.data})

        // return the response if you want to manipulate this response.
        return response;
    } catch (error) {
        return {error, type: 2, }
    }
}

export const customFetchListPublic = ({controller, validateIfDataExists = false, stateInStore, saveInStore = true, dispatchAction, methodName}) =>  async (dispatch) => {
    try {
        // validating if you want to validate if the data exists in store then validate that the data is greater than 0
        if(validateIfDataExists && stateInStore.length > 0) return {data: stateInStore, error: null};

        const response = await ApiServices[controller].customGETpublic(methodName);

        if(response.error) return {error: response.error, type: 2 }

        // Valitatin if you want to save in store the response
        if(saveInStore) dispatch({ type: dispatchAction, payload: response?.data})

        // return the response if you want to manipulate this response.
        return response;
    } catch (error) {
        return {error, type: 2, }
    }
}

export const customFetchListRegisterCriteria = ({controller, validateIfDataExists = false, stateInStore, saveInStore = true, dispatchAction, action = 'list'}) => async () => {
    try {

        if(validateIfDataExists && stateInStore.length > 0) return {data: stateInStore, error: null};

        const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);

        if(hasPermission.error) return {error: hasPermission.error, type: 1, };

        const response = await ApiServices[controller].listRegisterCriteria();

        if(response.error) return {error: response.error, type: 2 }

        if(saveInStore)  dispatchAction(response?.data)

        return response;
    } catch (error) {
        return {error, type: 2, }
    }
}

export const customActionRegister = ({controller, action, data, validateHasPermision = true}) => async () => {
    try {

        if(validateHasPermision) {
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);
            if(hasPermission.error) return {error: hasPermission.error, type: 1, };
        }

        let response = {};
        if(action === 'create') response = await ApiServices[controller].createRegister(data);
        else response = await ApiServices[controller].updateRegister(data);

        if(response.error) return {error: response.error, type: 2 }

        return response;

    } catch (error){
        return {error, type: 2, }
    }
}


export const customPatchRegisterTwo = ({controller, action, data, method, methodAction = 'PATCH', validateHasPermision = true}) => async () => {
    try {

        if(validateHasPermision) {
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);

            if(hasPermission.error) return {error: hasPermission.error, type: 1, };
        }
        const response = await ApiServices[controller].customPATCHTwo(action, method, data, methodAction);

        if(response.error) return {error: response.error, type: 2 }

        return response;

    } catch (error){
        return {error, type: 2, }
    }
}

export const customPatchRegisterThree = ({controller, action, data, method, methodTwo, methodAction = 'PATCH', validateHasPermision = true}) => async () => {
    try {

        if(validateHasPermision) {
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);

            if(hasPermission.error) return {error: hasPermission.error, type: 1, };
        }

        const response = await ApiServices[controller].customPATCHThree(action, method, methodTwo, data, methodAction);

        if(response.error) return {error: response.error, type: 2 }

        return response;

    } catch (error){
        return {error, type: 2, }
    }
}


export const customFetchListDepartmentsRegister = ({controller, validateIfDataExists = false, stateInStore, saveInStore = true, dispatchAction, action = 'list', validateHasPermision = true}) => async (dispatch) => {
    try {

        // validating if you want to validate if the data exists in store then validate that the data is greater than 0
        if(validateIfDataExists && stateInStore.length > 0) return {data: stateInStore, error: null};

        if(validateHasPermision) {
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, action);
            if(hasPermission.error) return {error: hasPermission.error, type: 1, };
        }

        const response = await ApiServices[controller].listRegister();

        let newData = [];
        // to remove items you won't use
        response.data.forEach( item => {
            if( item.id !== 0 && item.id !== 23 && item.id !== 24 && item.id !== 25) newData.push(item)
        });

        if(response.error) return {error: response.error, type: 2 }

        // Valitatinf if you want to save in store the response
        if(saveInStore) dispatch({ type: dispatchAction, payload: newData});

        // return the response if you want to manipulate this response.
        return {data: newData};
    } catch (error) {
        return {error, type: 2, }
    }
}

export const customFetchListMunicipality =  ({controller, stateInStore, dispatchAction, idDeparment, validateHasPermision = true}) => async (dispatch) => {

    try {

        if(stateInStore && stateInStore.length > 0) {
            const currentMunicipality = stateInStore?.filter( ({idMunicipio}) => Number(idMunicipio?.departamentoId) === Number(idDeparment));
            if(currentMunicipality?.length > 0) return {data: currentMunicipality, error: null};
        };

        if(validateHasPermision) {
            const hasPermission = await ApiServices.userSecurity.hasPermission(controller, 'list');

            if(hasPermission.error) return {error: hasPermission.error, type: 1, };
        }

        const response = await ApiServices[controller].listRegisterCriteria();

        if(response.error) return {error: response.error, type: 2 }

        // to remove items you won't use
        let newData = response.data.filter( item => Number(item.id) !== 0);

        dispatch({ type: dispatchAction, payload: newData});

        response.data = newData;

        return response;
    } catch (error) {
        return {error, type: 2, }
    }
}

export const customSetDataStore = ({dispatchAction, data}) => async (dispatch) => {
    dispatch({type: dispatchAction, payload: data})
    return data;
}