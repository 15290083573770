export const actionNames={
    // MENU SELECTED ITEMS
    SET_MENU_ITEM_GROUP:"SET_MENU_ITEM_GROUP",
    SET_MENU_ITEM:"SET_MENU_ITEM",

    //security
    SET_CURRENT_USER:"SET_CURRENT_USER",
    SET_MENU:"SET_MENU",
    LOGOUT:"LOGOUT",
    REGISTER:"REGISTER",

    //base64
    DOCUMENTOBASE64_SET_DATA:"DOCUMENTOBASE64_SET_DATA",
    DOCUMENTOBASE64_PARTIDOPOLITICO_SET_DATA:"DOCUMENTOBASE64_PARTIDOPOLITICO_SET_DATA",
    DOCUMENTOBASE64_CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA: "DOCUMENTOBASE64_CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA",
    DOCUMENTOBASE64_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA: "DOCUMENTOBASE64_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA",

    //PADRON GOZE DERECHOS
    PADRON_GOCEDERECHOS_DELEGACION_SET_DATA:"PADRON_GOCEDERECHOS_DELEGACION_SET_DATA",
    PADRON_SET_DATA:"PADRON_SET_DATA",

    //CVE
    UUID_SET_DATA: "UUID_SET_DATA",
    UUID_SET_DATA_CONSTANCIADECANDIDATOELECCIONPOPULAR: "UUID_SET_DATA_CONSTANCIADECANDIDATOELECCIONPOPULAR",
    UUID_SET_DATA_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA: "UUID_SET_DATA_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA",
    //DATA DE CONSULTA PADRON PARTIDO POLITICO
    PADRON_AFILIACION_PARTIDOPOLITICO_SET_DATA:"PADRON_AFILIACION_PARTIDOPOLITICO_SET_DATA",
    PADRON_PARTIDOPOLITICO_DELEGACION_SET_DATA:"PADRON_PARTIDOPOLITICO_DELEGACION_SET_DATA",
    UUID_PARTIDOPOLITICO_SET_DATA: "UUID_PARTIDOPOLITICO_SET_DATA",
    //DATA PADRON DE CONSULTA CONSTANCIAS 
    PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA:"PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA",
    PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA:"PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA",
    PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_ORGANIZACIONESPOLITICAS_SET_DATA:"PADRON_CONSTANCIACANDIDATOELECCIONPOPULAR_ORGANIZACIONESPOLITICAS_SET_DATA",
    PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_ORGANIZACIONESPOLITICAS_SET_DATA:"PADRON_CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_ORGANIZACIONESPOLITICAS_SET_DATA",
    //CERTIFICADOS 
    CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA:"CERTIFICACIONDEAFILIACIONAPARTIDOPOLITICO_SET_DATA",
    CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA: "CERTIFICACIONDEGOCEDESUSDERECHOSPOLITICOS_SET_DATA",

    //CONSTANCIAS
    CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA:"CONSTANCIACANDIDATOELECCIONPOPULAR_SET_DATA",
    CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA:"CONSTANCIACANDIDATOELECCIONPOPULAR_DELEGACION_SET_DATA",
    CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA:"CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_SET_DATA",
    CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA:"CONSTANCIADENOSERDIRECTIVOMANDATARIODEORGANIZACIONPOLITICA_DELEGACION_SET_DATA",

    //Solicitud
    SOLICITUD_SET_DATA:"SOLICITUD_SET_DATA",

    //sign up stepper
    SIGNUP_SET_DATA : "SIGNUP_SET_DATA",
    SIGNUP_SET_FILES: "SIGNUP_SET_FILES",
    SIGNUP_SET_FILE_BY_INDEX:  "SIGNUP_SET_FILE_BY_INDEX",
    SIGNUP_SET_STEP:  "SIGNUP_SET_STEP",
    SIGNUP_SET_TOKEN_RECAPCHA:"SIGNUP_SET_TOKEN_RECAPCHA",
    SIGNUP_SET_RECAPTCHA_STATUS:"SIGNUP_SET_RECAPTCHA_STATUS",
    SIGNUP_SET_ARCHIVO_SOLICITUD_GRANDE:"SIGNUP_SET_ARCHIVO_SOLICITUD_GRANDE",
    SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE:"SIGNUP_SET_ARCHIVO_DPI_FRONTAL_GRANDE",
    SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE:"SIGNUP_SET_ARCHIVO_DPI_TRASERA_GRANDE",
    SIGNUP_SET_ARCHIVO_FOTO_DPI_GRANDE:"SIGNUP_SET_ARCHIVO_FOTO_DPI_GRANDE",
    SIGNUP_SET_TIPO_ARCHIVO_SOLICITUD_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_SOLICITUD_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_DPI_FRONTAL_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_DPI_TRASERA_DIFERENTE",
    SIGNUP_SET_TIPO_ARCHIVO_FOTO_DPI_DIFERENTE:"SIGNUP_SET_TIPO_ARCHIVO_FOTO_DPI_DIFERENTE",
    SIGNUP_SET_NOMBRE_TITULO_ARCHIVO: "SIGNUP_SET_NOMBRE_TITULO_ARCHIVO",

    GET_DEPENDENCIES: "GET_DEPENDENCIES",
    GET_DEPARTMENTS: "GET_DEPARTMENTS",
    GET_MUNICIPALITIES: "GET_MUNICIPALITIES",
    SET_GENERAL_DATA: "SET_GENERAL_DATA",
    SET_VOTER_TURNOUT: "SET_VOTER_TURNOUT",
    SET_EDUCATION_LEVEL: "SET_EDUCATION_LEVEL",
    SET_WORK_EXPERIENCE: "SET_WORK_EXPERIENCE",
    SET_DATA_ACTIVE_AND_NUMBER_STEP: "SET_DATA_ACTIVE_AND_NUMBER_STEP",
    CLEAN_ALL_REDUCER: "CLEAN_ALL_REDUCER",

    // Datos generales
    SET_SEXUAL_ORIENTATION: "SET_SEXUAL_ORIENTATION",
    SET_ETHNICITY: "SET_ETHNICITY",
    SET_LINGUISTIC_COMMUNITY: "SET_LINGUISTIC_COMMUNITY",
    SET_GENDER_IDENTITY: "SET_GENDER_IDENTITY",
    SET_DISABILITIES: "SET_DISABILITIES",
    //Participación electoral
    SET_ELECTORAL_CHARGE: "SET_ELECTORAL_CHARGE",

    // EDUCATION LEVEL
    SET_EDUCATION_LEVEL_LIST: "SET_EDUCATION_LEVEL_LIST",
    SET_PROFESSION_OR_OCCUPATION: "SET_PROFESSION_OR_OCCUPATION",
    SET_EDUCATIONAL_INSTITUTIONS: "SET_EDUCATIONAL_INSTITUTIONS",
    SET_CURRENT_EDUCATION_LEVEL_LIST: "SET_CURRENT_EDUCATION_LEVEL_LIST",

    // WORK EXPERIENCE
    SET_RANGE_EXPERIENCE: "SET_RANGE_EXPERIENCE",
    SET_LABORAL_SECTOR: "SET_LABORAL_SECTOR",
    LIST_CATALOGOS_POR_FORMULARIO: "LIST_CATALOGOS_POR_FORMULARIO",
};