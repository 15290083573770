import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '0px auto',
        width: '60%',
        marginTop: '60px',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
        '@media (max-width:768px)':{
            width: '90%',
            marginTop: '180px',
        },
    },
    title: {
        fontSize: '24px',
    },
    description: {
        fontSize: '20px',
    }
}));

const InscriptionFinished = ({title, description, description2}) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Alert severity="warning" >
                <AlertTitle className={classes.title}> <strong>{title}</strong></AlertTitle>
                <strong className={classes.title}>{description}</strong>
                <div>
                    <strong className={classes.title}>{description2}</strong>
                </div>
            </Alert>
        </div>
    );
}

export default InscriptionFinished;