import React, {useState} from 'react';
import {Grid, Card, CardActionArea, CardContent, Divider, IconButton, Button } from '@material-ui/core/';
import {useStyles} from './styles';
import {Visibility} from '@material-ui/icons';
import Base64Pdf from '../base64pdf';
import EditIcon from '@material-ui/icons/Edit';

function Card64Pdf(props) {
    const classes = useStyles(props),
    [verPDF, setVerPDF] = useState(false),
    [datosPDF, setDatosPDF] = useState({pdf:'', title:'',});

    const VerPDF = async (datosPDF)=>{

        if(props.getDocumentOfPoliticalOrganization) {
            const data = await props.getDocumentOfPoliticalOrganization(datosPDF.documentName);
            if(data) {
                setDatosPDF({pdf: data?.pdf, title: props?.label})
                 setVerPDF(true)
            }
        } else {
            if(datosPDF?.pdf) {
                setDatosPDF({pdf: datosPDF?.pdf, title: props?.label})
                setVerPDF(true)
            }
        }
    }

    const CerrarVerPDF =()=>{
        setVerPDF(false)
    }

    return (
        <div>
            <Grid container className={classes.rootGrid} justifyContent="center" >
                <Grid item className={classes.itemGrid}>
                    <Card className={classes.rootCard} >
                        <CardActionArea>
                            <div className={props.contentMediaTable ? classes.contentMediaTable : classes.contentMedia}>
                            {props.pdf ? (
                                <iframe src={props.pdf.includes('base64,') ? props.pdf : `data:application/pdf;base64,${props.pdf}`} width="100%" height="100%" allowFullScreen loading={"lazy"}/>
                            ) : (
                                <img
                                    width={props.width} height={props.height}
                                    alt="img to upload file"
                                    src={props.src ? props.src : "/assets/simboloPdf.svg"}
                                />
                            )}
                            </div>
                            {props.showTitle && (
                                <CardContent  className={classes.cardContent}>
                                    <Grid item xs={12}>
                                        <div className={classes.centerTitle}><Divider /> <strong>{props.label}</strong> <Divider /></div>
                                    </Grid >
                                </CardContent>
                            )}
                        </CardActionArea>
                        <IconButton size={"medium"} className={classes.iconVisibility} onClick={()=>VerPDF({pdf: props.pdf, documentName: props.documentName})}><Visibility  style={{ fontSize: props.contentMediaTable ? 25 : 30 }} /></IconButton>
                    </Card>
                    {props.showTitle2 && (
                    <CardContent  className={classes.cardContent}>
                        <Grid item xs={12}>
                            <div className={classes.centerTitle2}><Divider /> <strong >{props.title}</strong> <Divider /></div>
                        </Grid >
                        {props.showButtonEditDocument && (
                            <Button variant="contained" color="primary" className={classes.button} onClick={() => props.handleClickUpdateDocument(props)}>
                                <EditIcon  />
                                {props.firstTitle} {props.title}
                            </Button>
                        )}
                    </CardContent>
                    )}
                </Grid>
                <Base64Pdf pdf={datosPDF.pdf} title={datosPDF.title} open={verPDF} handleClose={CerrarVerPDF}  showDownloadButton={props.showDownloadButton} src={props.src} />
            </Grid>
        </div>
    );
}

Card64Pdf.defaultProps = {
    showInMovil : false,
    showTitle2: false,
    showButtonEditDocument: false,
    firstTitle: "Actualizar ",
    width:"80%",
    height:"80%",
    contentMediaTable: false,
    handleClickUpdateDocument: () => {},
}


export default Card64Pdf;