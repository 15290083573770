import React from 'react';
import { Divider } from '@material-ui/core';

export default function TitleForm(props) {
    return (
        <>
        {props.showTitle && (
            <div key={props.name} style={{ textAlign: props.textAlign }} className={props.className} >
                <Divider /> <strong>{props.text}</strong> <Divider />
            </div>
        )}
    </>
    )
}

TitleForm.defaultProps = {
    showTitle: true,
}