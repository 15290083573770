//React
import React, {useEffect} from 'react';
import clsx from 'clsx';
import {  useTheme } from '@material-ui/core/styles';
import { publicMenu } from 'constants/index';
import { IconButton, Drawer, CssBaseline, AppBar , Toolbar, Typography, Button, Divider, Icon, Fab } from '@material-ui/core';
//Router
import {useHistory, Route,  Switch, Link, Redirect } from 'react-router-dom';
//Icon
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
//components
import UserAccount from 'pages/Security/Login/FormElements/UserAccount';
import VerticalMenu from 'components/VerticalMenu';

//Redux
import {connect } from "react-redux";
import mapStateToProps from './mapStateToProps';
import mapDispatchToProps from './mapDispatchToProps';
//styles
import {useStyles} from './Style';

import {  customSetDataStore } from 'store/actions/customAction';
import { actionNames } from "constants/actionNames";
import { useDispatch } from 'react-redux';
import InscriptionForm from 'pages/InscriptionForm';
import { NotFound } from 'commons';
import { tiposDeFormularios } from 'constants/globalsStates';
import InscriptionFinished from 'pages/inscriptionFinished';

const Menu=(props)=> {
    const classes = useStyles();
    const theme = useTheme();
    var authenticated =props.authenticated;
    const [open, setOpen] = React.useState(false);
    const [principalTitle, setPrincipalTitle] = React.useState({
        firstTitle: "",
        secondTitle: "",
        thirdTitle: ""
    });
    let history = useHistory();
    const dispatch = useDispatch();

    const onLogout =()=>{
        let userAccount = new UserAccount();
        userAccount.logout();
        props.LOGOUT();
        props.SET_MENU(publicMenu);
        history.push("/login",{})
    }

    const handleDrawerClose=(props)=> {
        setOpen(false);
    }
    const cancelAction = () => {
        dispatch(customSetDataStore({ dispatchAction: actionNames.CLEAN_ALL_REDUCER }));
        history.replace("/");
    };

    useEffect( () => {
        if(history.location.pathname === "/contadores") {
            setPrincipalTitle({
                firstTitle: "Tribunal Supremo Electoral",
                secondTitle: "Dirección de Finanzas",
                thirdTitle: "Departamento de Contabilidad"
            })
        } else {
            setPrincipalTitle({
                firstTitle: "Instituto de Formación y Capacitación Cívica, Política y Electoral.",
                secondTitle: "Departamento de Capacitación Electoral y Educación Cívica.",
                thirdTitle: "Instituto Electoral"
            })
        }
    }, [])

    const {firstTitle, secondTitle, thirdTitle} = principalTitle;

  return (

    <div className={classes.root}>
        <CssBaseline />
        <AppBar position="fixed"
                className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
                })}>
            <Toolbar className={classes.toolbar}>
                <Typography variant="h6" className={classes.title} >
                    <Link className={classes.appTitle} to="/">
                        <img className={classes.appIcon} src="assets/WhiteIcon.svg"  alt="fireSpot"/>
                        {firstTitle}
                        <br/>
                        {secondTitle}
                        <br/>
                        {thirdTitle}
                    </Link>
                </Typography>
                { props.authenticated ? (
                <div>
                     {/* <Button className={classes.button} color="inherit"  href="https://ayudaportalweb.tse.org.gt/es/Registro_de_medios" onClick={cancelAction} target="_blank">
                        <Icon className={classes.icon}><HelpOutlineIcon/></Icon>
                        <span className={classes.title_text}>AYUDA</span>
                    </Button> */}
                    {/* <Button className={classes.button} onClick={cancelAction} color="inherit"  to='/' component={Link}>
                        <Icon className={classes.icon}><HomeIcon/></Icon>
                        <span className={classes.title_text}>Menú Principal</span>
                    </Button> */}
                </div>
                ):(
                <div>
                    <Button className={classes.button} color="inherit" to='/login'
                            component={Link}>
                        <Icon   className={classes.icon}>person</Icon>
                    {"   Iniciar sesión"} </Button>
                </div>
                )}
            </Toolbar>
        </AppBar>

        <Drawer className={classes.drawer}  variant="persistent"    anchor="left"
                open={open} classes={{  paper: classes.drawerPaper,}}
        >
            <div className={classes.drawerHeader}>
                <Fab color="primary" aria-label="add" className={classes.fab}>
                    <img className={classes.image}src={"/svgMenu/luz.svg"} alt="Italian Trulli"></img>
                </Fab>
                Bienvenido
                <IconButton className={classes.iconMenu}  onClick={handleDrawerClose}>
                    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
            </div>
            <Divider />
                <VerticalMenu/>
            <Divider variant="middle"/>
        </Drawer>

        <main className={clsx(classes.content, {  [classes.contentShift]: open,})}>
            <Switch>
                <Route  path="*" render={() => <InscriptionFinished title={"Información importante!"} description={"La convocatoria ha sido cerrada, gracias por participar."} description2="Departamento de Capacitación Electoral y Educación Cívica." />}></Route>
            </Switch>
        </main>

    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
