import React, { Component } from 'react';
import './style.css';
import { Link } from 'react-router-dom';

class NotFound extends Component {
    render() {
        return (
            <div className="page-not-found">
                <h1 className="title">
                    404
                </h1>
                <div className="desc">
                    No se encontró la página que está buscando.
                </div>
                <a href="https://www.tse.org.gt/index.php/registro-de-medios">Regresar</a>
            </div>
        );
    }
}

export default NotFound;