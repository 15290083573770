import React, {Fragment} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import Icon from "@material-ui/core/Icon";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    "@media (max-width:567px)": {
      marginTop: 40,
      //ipad
    },
  },
  h1: {
    textAlign: "center",
    color: "#000",
    fontFamily: "sans-serif",
    "@media (max-width:2000px)": {
      // web
      fontSize: "1.6rem",
    },
    "@media (max-width:1000px)": {
      // web
      fontSize: "1.5rem",
    },
    "@media (max-width:768px)": {
      //ipad
      fontSize: "1.2rem",
    },
    "@media (max-width:415px)": {
      //mobile
      fontSize: "1rem",
      marginRight: "10%",
    },
  },
  icon: {
    "margin-right": 10,
    width: "65px",
    height: "65px",
  },
  divider: {
      background: '#000',
      marginBottom: 30,
  }
}));

function Title(props) {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.container}>
        {props.showIcon && (
          <img className={classes.icon} src={`/menu/${props.icon ? props.icon : "Formulario"}.png`} alt="Icon Formulario"/>
          // <Icon className={classes.icon} fontSize="large">
          //   {props.icon ? props.icon : "description"}
          // </Icon>
        )}
        <h1 className={classes.h1}>{props.title}</h1>
      </div>
      <Divider  className={classes.divider}/>
    </Fragment>
  );
}

Title.defaultProps = {
  showIcon: true,
  icon: "Formulario"
}

export default Title;
