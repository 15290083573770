import { makeStyles } from '@material-ui/core/styles';

export  const useStyles = makeStyles(theme => ({
    formControl:{
        minWidth: 300,
        marginTop:'1%',
        paddingRight: theme.spacing(2),
		width: '25%',
		'@media (max-width:992px)':{ //ipad
			width: '50%'
		},
		'@media (max-width:768px)':{ //ipad
			width: '100%'
		},
    },
}));
