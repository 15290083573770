import {actionNames} from "constants/actionNames";

const generalData = {
    primerNombre: "",
    segundoNombre: "",
    primerApellido: "",
    segundoApellido: "",
    apellidoDeCasada: "",
    correoElectronico: "",
    telefonoContacto: "",
    estadoCivil: "",
    cui: "",
    fechaNacimiento: new Date(),
    sexo: "",
    identidadDeGenero: null,
    orientacionSexual: null,
    puebloPerteneciente: null,
    comunidadLinguistica: [],
    discapacidad: "",
    discapacidades: [],
    otraDiscapacidad: "",
    residencia: "",
    codDepartamento: null,
    codMunicipio: null,
    autoIdentificacionGenero: null,
    validateOrientacionSexual: null,
}

const voterTurnout = {
    cursosRecibidos: "",
    participadoPE: "",
    tipoEdicionEscrito: "",
    otraParticipacion: "",
    participoComo: []
}

const educationLevel = {
    nivelEducativo: null,
    profesionOficio:  null,
    estudioActual: "",
    nombreEstudioActual: null,
    estudiaEstablecimiento: null,
    universidad: null,
}

const workExperience = {
    trabajaActual: "",
    sectorLabora: null,
    nombreInstitucionLabora: "",
    ambitoExperienciaLaboral: null,
    otraExperienciaLaboral: "",
    rangoExperienciaLaboral:  null,
    nombresInstitucionesLaborado: [],
}

const initialState = {
    generalData,
    voterTurnout,
    educationLevel,
    workExperience,
    activeStep: 0,
    numberStep: 1,
};

export default (state = initialState, action ) => {
    switch (action.type) {

        case actionNames.SET_GENERAL_DATA:
            return {
                ...state,
                generalData: {...action.payload.generalData},
                activeStep: action.payload.activeStep,
                numberStep: action.payload.numberStep,
            }

        case actionNames.SET_VOTER_TURNOUT:
            return {
                ...state,
                voterTurnout: action.payload.voterTurnout,
                activeStep: action.payload.activeStep,
                numberStep: action.payload.numberStep,
            }

        case actionNames.SET_EDUCATION_LEVEL:
            return {
                ...state,
                educationLevel: {...action.payload.educationLevel},
                activeStep: action.payload.activeStep,
                numberStep: action.payload.numberStep,
            }

        case actionNames.SET_WORK_EXPERIENCE:
            return {
                ...state,
                workExperience: {...action.payload.workExperience},
                activeStep: action.payload.activeStep,
                numberStep: action.payload.numberStep,
            }

        case actionNames.SET_DATA_ACTIVE_AND_NUMBER_STEP:
            return {
                ...state,
                activeStep: action.payload.activeStep,
                numberStep: action.payload.numberStep,
            }

        case actionNames.CLEAN_ALL_REDUCER:
            return {
                generalData,
                voterTurnout,
                educationLevel,
                workExperience,
                activeStep: 0,
                numberStep: 1,
            }

        default:
            return state;
    }
}