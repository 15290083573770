import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    rootSize: {
        // width: 640,
        "&>.MuiDialog-paperWidthXl":{
            width: 640,
        },
        "&>..MuiDialog-scrollPaper":{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
        }
    },
    dialogContent:{
        display: "flex",
        justifyContent: "center"
    },
    img:{
        width: 500,
    },
    title: {
        textAlign: "center",
    },
    message:{
        marginLeft: '5%',
        marginRight: '5%',
    },
    circularProgress:{
        "&>div":{
            color:"#fff",
            width:"25px !important",
            height:"25px !important",
            marginRight: 7,
        }
    },
    button:{
        margin: 'auto',
        marginLeft: '45%',
        fontSize: '2em'
    },
    icon:{
        marginRight: 6,
        marginLeft: -6,
    },
}))

