import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  buttonBack: {
    marginRight: theme.spacing(1),
    "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)",
    "border":"0px",
    "borderRadius":"3",
    "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37,
    padding:"0 30px",
    marginBottom: 5,
  },
  buttonNext: {
    marginRight: theme.spacing(1),
    "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)",
    "border":"0px",
    "borderRadius":"3",
    "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37,
    padding:"0 30px",
    marginBottom: 5,
  },
  buttonClosed: {
    marginRight: theme.spacing(1),
    "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)",
    "border":"0px",
    "borderRadius":"3",
    "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)",
    "color":"white",
    "height":37,
    padding:"0 30px",
    marginBottom: 5,
  },
  content: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  containerButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap'
  }
}));
