import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
    hideInPrintView:{
        '@media print':{
            display:'none',
        },
    },
    root:{
        marginTop:'5px',
        margin:' 0 3%',
        '@media (max-width: 460px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    grid:{
        width: 300,
        display: 'inline-block',
    },
    formContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        flexWrap: "wrap",
    },
    buttonContainer: {
        marginTop: 40,
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
    },
    button:{
        "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)",
        "border":"0px", "borderRadius":"3",
        "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white",
        width: 285,
        marginRight: 15,
        marginBottom: 10,
        textAlign: 'center',
        '@media (max-width:768px)':{ //ipad
            width: '100%',
		},
    },
    buttonCancel: {
        width: 285,
        marginRight: 15,
        marginBottom: 10,
        textAlign: 'center',
        "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)",
        "border":"0px", "borderRadius":"3",
        "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white",
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    },
    select: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    checkbox:{
        marginTop:5,
        width: 300
    },
    circularProgress:{
        "&>div":{
            color:"#fff",
            width:"25px !important",
            height:"25px !important",
            marginRight: 7,
        }
    },
    title: {
        marginTop:'2rem',
        marginBottom: '10px',
        backgroundColor:'gainsboro',
        width: '100%',
        fontSize: '1rem'
    },
}));