import React, {useState, Fragment, useEffect} from 'react';
import { Card, CardContent } from '@material-ui/core';
import Form from 'components/Form/FormTwoColumns';
import { Close, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { customFetchListPublic, customSetDataStore } from 'store/actions/customAction';
import { useDispatch, useSelector } from 'react-redux';
import { actionNames } from 'constants/actionNames';
import { useHistory } from 'react-router-dom';
import ApiServices from 'services/ApiServices';
import LoadingSpinner from 'components/LoadingSpinner';
import { showMessageError } from 'services/SweetAlert';

let universityList = [];
let nivelEducativoList = [];
let generalCurrentEducationLevel = [];
const EducationLevel = ({activeStep, numberStep, typeForm}) => {
    const {educationLevel,} = useSelector( ({formRequest}) => formRequest);

    const {nivelEducativo, profesionOficio, estudioActual, nombreEstudioActual, universidad} = educationLevel;
    let history = useHistory();
    const dispatch = useDispatch();
    const {general} = useSelector((state) => state);
    universityList = general?.catalogos.institucionesEducativas;
    nivelEducativoList = general?.catalogos.nivelEducativo;
    generalCurrentEducationLevel = general?.catalogos.nivelEducativoActual;

    const [controllers] = useState({nivelEducativo: "listNivelEducativo", profesionUOficio: "listProfesionOficio", institucionesEducativas: "listInstitucionesEducativas", listCatalogosPorFormulario: "listCatalogosPorFormulario"})
    const[catalogoController] = useState("catalogos");
    const[loadingRequest, setLoadingRequest] = useState(false);

    useEffect(() => {
        validateAnswerOfCurrenStudy(estudioActual)
        getData(general?.catalogos.listCatalogosPorFormulario, actionNames.LIST_CATALOGOS_POR_FORMULARIO, controllers.listCatalogosPorFormulario, "")
    }, [])

    const getData = async (stateInStore, dispatchAction, methodName, nameElement) => {

        const newState = Object.assign({}, elements);
        setLoadingRequest(true)
        const dataCustomFetch = {
            controller: catalogoController,
            validateIfDataExists: true,
            stateInStore,
            dispatchAction,
            methodName
        }

        ApiServices[catalogoController].searchCriteria.clear();
        ApiServices[catalogoController].searchCriteria.addEquals('idFormulario', typeForm.id);
        ApiServices[catalogoController].orderCriteria.clear();
        ApiServices[catalogoController].orderCriteria.addAsc('id');
        let response = await dispatch(customFetchListPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error) {
            const message = response?.error?.message || 'No se encontraron datos';
            if (response?.type === 1)  return showMessageError(message,'')
            if (response?.error?.status === 404)  return showMessageError(message, '')
            return showMessageError(message, '')
        }

        if(nameElement) {
            await dispatch(customSetDataStore({
                dispatchAction : actionNames.SET_EDUCATIONAL_INSTITUTIONS,
                data: response.data
            }));
            newState[nameElement].list = response?.data;
            return setElements(newState);
        }

        let educationLevel = [];
        let profession = []
        if(response?.data.length > 0) {
            const {nivelEducativo, profesionOficio} = response.data[0];
            educationLevel = nivelEducativo.map( ({nivelEducativo } ) => ({id: nivelEducativo.id, name: nivelEducativo.name, actual: nivelEducativo.actual}))
            profession = profesionOficio.map( ({profesionOficio } ) => ({id: profesionOficio.id, name: profesionOficio.name}))
        }

        if(typeForm.id === 2) educationLevel = educationLevel.filter( (item) => item.id === 4 || item.id === 8);

        await dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_EDUCATION_LEVEL_LIST,
            data: educationLevel
        }));

        newState.nivelEducativo.list = educationLevel;
        newState.profesionOficio.list = profession;
        setElements(newState);
    }

    const getDataCurrentLevelEducation = async () => {
        setLoadingRequest(true)
        const dataCustomFetch = {
            controller: catalogoController,
            validateIfDataExists: true,
            stateInStore: generalCurrentEducationLevel,
            dispatchAction: actionNames.SET_CURRENT_EDUCATION_LEVEL_LIST,
            methodName: "listNivelEducativo"
        }

        ApiServices[catalogoController].searchCriteria.clear();
        ApiServices[catalogoController].orderCriteria.clear();
        let response = await dispatch(customFetchListPublic(dataCustomFetch));

        setLoadingRequest(false);

        if(response?.error) {
            const message = response?.error?.message || 'No se encontraron datos';
            if (response?.type === 1)  return showMessageError(message,'')
            if (response?.error?.status === 404)  return showMessageError(message, '')
            return showMessageError(message, '')
        }

        await dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_CURRENT_EDUCATION_LEVEL_LIST,
            data: response?.data
        }));
        return response?.data;
    }


    const changeValueToElement = (element, nameShowELement, valueShowElement, pattern, value = null) => {
        elements[element][nameShowELement] = valueShowElement;
        elements[element].pattern = pattern;
        elements[element].value = value;
    }

    const validateAnswerOfCurrenStudy = async (value) => {
        const newElements = Object.assign({}, elements);
        if(value === '1') {
            const educationLevelData = await getDataCurrentLevelEducation()
            newElements.nombreEstudioActual.list = educationLevelData?.filter(({actual}) => Number(actual) === 1)

            changeValueToElement('nombreEstudioActual', 'showSelectAutoComplete', true, "^[1-9][0-9]*$", nombreEstudioActual)
            getData(universityList, actionNames.SET_EDUCATIONAL_INSTITUTIONS, controllers.institucionesEducativas, "universidad")
            changeValueToElement('universidad', 'showSelectAutoComplete', true, "^[1-9][0-9]*$", universidad)
        }
        else {
            changeValueToElement('nombreEstudioActual', 'showSelectAutoComplete', false, "", )
            changeValueToElement('universidad', 'showSelectAutoComplete', false, "", )
        }
        setElements(newElements)
    }

    const [elements, setElements] = useState({
        nivelEducativo: {
            idelement: "nivelEducativo", value: nivelEducativo , label: "Seleccione el nivel Educativo* ", pattern:"^[1-9][0-9]*$", validators: ['required'],
            errorMessages:['Seleccione el nivel educativo'], isError: false, showSelectAutoComplete:true, elementType:'autocompleteV2', list: [], handler: undefined,
        },
        profesionOficio: {
            idelement: "profesionOficio", value: profesionOficio , label: "Seleccione la profesión *", pattern:"^[1-9][0-9]*$", validators: ['required'],
            errorMessages:['Seleccione la profesión'], isError:false, showSelectAutoComplete: true, elementType:'autocompleteV2', list: [], handler: undefined,
        },
        estudioActual:{
            idelement: "estudioActual", value: estudioActual, label: "¿Estudia actualmente?", isError:false, elementType:'radio', errorMessages:['Debe marcar una opción'],
            options : [{ value: "0", label: 'No' }, { value: "1", label: 'Si' }], 'disabled':false, isRequired: true, handler: validateAnswerOfCurrenStudy
        },
        nombreEstudioActual: {
            idelement: "nombreEstudioActual", value: nombreEstudioActual, label: "Seleccione el nivel de estudio actual *", pattern:"", validators: ['required'],
            errorMessages:['Seleccione el nivel de estudio actual'], isError:false, showSelectAutoComplete: false, elementType:'autocompleteV2', list: [], handler: undefined,
        },
        universidad: {
            idelement: "universidad", value: universidad, label: "Seleccione la universidad *", pattern:"", validators: ['required'],
            errorMessages:['Seleccione la universidad'], isError:false, showSelectAutoComplete: false, elementType:'autocompleteV2', list: [], handler: undefined,
        },
    });

    const saveData = async (dataForm) => {
        await dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_EDUCATION_LEVEL,
            data: {
                educationLevel: dataForm,
                activeStep : activeStep + 1,
                numberStep : numberStep + 1
            }
        }));
    }

    // cancel action of the form
    const cancelAction = () => {
        dispatch(customSetDataStore({ dispatchAction : actionNames.CLEAN_ALL_REDUCER}));
        history.replace("/");
    };

    const backAction = () => {
        dispatch(customSetDataStore({
            dispatchAction : actionNames.SET_DATA_ACTIVE_AND_NUMBER_STEP,
            data: {
                activeStep : activeStep - 1,
                numberStep : numberStep - 1
            }
        }));
    };

    // elements button action to show of the form
    const [buttonList]= React.useState({
        "backAction":{"label":"Regresar", "icon": <NavigateBefore />,"callback":backAction,"loading":false,"size":"medium",
            "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)", "border":"0px", "borderRadius":"3",
            "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px",  disabled: false, isCancel: true,
        },
        "saveData":{"label":"Siguiente", "icon": <NavigateNext />, "callback":saveData,"loading":false,"size":"medium",
                "background":"linear-gradient(45deg, #205690 30%, #3890EE 90%)", "border":"0px", "borderRadius":"3",
                "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px", disabled: false,
                isCancel: false,
        },
        "cancelAction":{"label":"Cancelar", "icon": <Close />,"callback":cancelAction,"loading":false,"size":"medium",
                "background":"linear-gradient(45deg, #B90000 30%, #E07777 90%)", "border":"0px", "borderRadius":"3",
                "boxShadow":"0 3px 5px 2px rgba(33, 203, 243, .3)", "color":"white", "height":37, padding:"0 30px",  disabled: false,
                isCancel: true,
        },
    });

    return (
        <Fragment>
            <Card>
                {loadingRequest && <LoadingSpinner open={loadingRequest} /> }
                <CardContent>
                    <Form
                        elements={elements}
                        buttonList={buttonList}
                    />
                </CardContent>
            </Card>
        </Fragment>
    );
}

export default EducationLevel;