

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme=>({

    title:{
        color: '#000',
        opacity:'0.6',
        padding: '6% 0% 4% 4%',
        fontSize: '0.96rem',
        fontFamily: 'sans-serif',
        fontWeight: '600',
        letterSpacing:'-0.05rem',
    },
}))