import React, { useState } from 'react';
import {useStyles} from 'components/Form/InputFile/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import Typography from '@material-ui/core/Typography';
import { EditorState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html';
import {Editor} from "react-draft-wysiwyg"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

export default function EditorDrawft(props) {
    const classes = useStyles(props),
    [editorState, setEditorState] = useState(EditorState.createEmpty());

    const onEditorStateChange = (editor) => {
        const htmlConvert = draftToHtml(convertToRaw(editor.getCurrentContent()));
        props.onEditorStateChange(htmlConvert, props)
        setEditorState(editor)
    };

    return (
        <>
            {props.showEditor && (
                <>
                    <Typography className={classes.titleFile}> {props.label} </Typography>
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={onEditorStateChange}
                        localization={{
                            locale:  props.locale
                        }}
                    />
                    {(props.isError) ? <FormHelperText className={classes.errorMessagesFileEditor} id="component-error-text">{props.errorMessages}</FormHelperText> : null }
                </>
            )}
        </>
    )
}

EditorDrawft.defaultProps = {
    locale : 'es',
    showEditor: true
}