import React, { useState } from 'react';
import {Input, InputLabel} from '@material-ui/core/';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useStyles} from './style';
import { PropTypes } from 'prop-types';

export default function FormControlInput(props) {
    const classes = useStyles();
    const [self] = useState(props.this);

    return (
        <FormControl className={classes.formControl} >
            <InputLabel htmlFor="component-error" className={classes.InputLabel}>{props.label}</InputLabel>
            <Input error={props.isError}
                inputProps={{ maxLength: props.maxLength, minLength: props.minLength, min: props.min, max: props.max}}
                key={props.name}
                id={props.name}
                name={props.name}
                value={props.value}
                autoFocus={true}
                onChange={(event)=>{
                    props.handleChange(event,self)}}
                onKeyPress={props.keyPress}
                disabled={props.disabled}
                aria-describedby="component-error-text"
                type={props.type}
            />
            {(props.isError) ? <FormHelperText className="Mui-error" id="component-error-text">{props.errorMessages}</FormHelperText> : null }
        </FormControl>

    )
}

FormControlInput.defaultProps = {
    type: "number",
    min: 1,
    max: 20
}