import ApiGenericServicesData from 'services/ApiGenericServicesData';
// import UserSecurity from 'services/UserSecurity';
// import User from 'services/User';
// import SolicitudUsuario from 'services/SolicitudUsuario';

class ApiServices{
    constructor(){
    //     this.user = new User();
    //     this.userSecurity=new UserSecurity();
    //     this.solicitudUsuario = new SolicitudUsuario();
        this.departamentos = new ApiGenericServicesData("departamentos", true);
        this.municipio = new ApiGenericServicesData("municipio", true);
        this.empresa = new ApiGenericServicesData("empresa", true);
        this.catalogos = new ApiGenericServicesData("catalogos");
        this.capacitacionElectoral = new ApiGenericServicesData("capacitacionElectoral", true);
        this.buscarDpi = new ApiGenericServicesData("buscarDpi", true);
    }
}

export default new ApiServices();

